exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-vercel-path-0-content-pages-acerca-de-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/vercel/path0/content/pages/acerca-de/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-vercel-path-0-content-pages-acerca-de-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-vercel-path-0-content-pages-en-general-presentation-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/vercel/path0/content/pages/en/general-presentation/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-vercel-path-0-content-pages-en-general-presentation-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-vercel-path-0-content-pages-presentacion-general-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/vercel/path0/content/pages/presentacion-general/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-vercel-path-0-content-pages-presentacion-general-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-vercel-path-0-content-pages-torres-de-granadilla-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/vercel/path0/content/pages/torres-de-granadilla/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-vercel-path-0-content-pages-torres-de-granadilla-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-vercel-path-0-content-projects-el-camino-de-consultas-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/vercel/path0/content/projects/el-camino-de-consultas/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-vercel-path-0-content-projects-el-camino-de-consultas-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-vercel-path-0-content-projects-el-inconsciente-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/vercel/path0/content/projects/el-inconsciente/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-vercel-path-0-content-projects-el-inconsciente-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-vercel-path-0-content-projects-el-sintoma-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/vercel/path0/content/projects/el-sintoma/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-vercel-path-0-content-projects-el-sintoma-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-vercel-path-0-content-projects-la-bioexistencia-consciente-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/vercel/path0/content/projects/la-bioexistencia-consciente/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-vercel-path-0-content-projects-la-bioexistencia-consciente-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

